import { NavigationHelper, useAppDispatch } from '@common';
import { getLocationParts, isIncludeOrEqual } from '@model-park/common';
import { Errors } from '@model-park/entities';
import { ILoginForm } from '@pages/Authentication/entities/interface';
import { useLoginMutation } from '@pages/Authentication/services/AuthService';
import { updateAuthState } from '@pages/Authentication/store';
import { FormInstance } from 'antd';
import Cookies from 'js-cookie';

export default function useLoginForm(onSuccess?: () => void) {
    const dispatch = useAppDispatch();
    const [login, { isLoading }] = useLoginMutation();
    const { maindomain } = getLocationParts();

    async function onFinish(values: ILoginForm, form: FormInstance<ILoginForm>) {
        try {
            const response = await login(values).unwrap();

            sessionStorage.setItem('authToken', response.authToken);
            if (values?.remember) {
                localStorage.setItem('authToken', response.authToken);
                Cookies.set('authToken', response.authToken, { domain: maindomain, expires: 30 });
            }

            if (response.authToken) {
                dispatch(updateAuthState(response));

                if (onSuccess) onSuccess();
                else {
                    NavigationHelper.Home().navigate();
                }
            }
        } catch (error: any) {
            if (isIncludeOrEqual(error?.data?.message, Errors.userNotFound)) {
                form.setFields([
                    {
                        name: 'email',
                        errors: [Errors.userNotFound],
                    },
                ]);
            }

            if (isIncludeOrEqual(error?.data?.message, Errors.passwordIncorrect)) {
                form.setFields([
                    {
                        name: 'password',
                        errors: [Errors.passwordIncorrect],
                    },
                ]);
            }
        }
    }
    return { isLoading, onFinish };
}
