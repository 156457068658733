import { getLocationParts } from '@model-park/common';
import { Typography } from 'antd';

const { Title, Paragraph } = Typography;

export function Installation() {
    const { maindomain } = getLocationParts();
    const host = `${window.location.protocol}//${maindomain}`;

    return (
        <>
            <Title level={3}>Installation</Title>
            <Title level={4}>Windows</Title>
            <Paragraph>
                Download the latest version from <a href={`${host}/dist/mpinstaller.exe`}>{`${host}/dist/mpinstaller.exe`}</a>
            </Paragraph>
            <Title level={4}>Linux</Title>
            <Paragraph>Run the following command in your terminal:</Paragraph>
            <Typography>
                <code>curl {host}/scripts/installer.sh | bash</code>
            </Typography>
        </>
    );
}
